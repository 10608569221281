import React from 'react';
import Slide from 'react-reveal/Slide';

import SEO from '../components/seo';

import { Menu, Item } from '../styles/styles.js';

const Pizzas = () => (
  <>
    <SEO title="Pizzas" />

    <Menu className="menu">
      <Slide left cascade duration={500}>
        <h1 className="menu-title">Pizzas</h1>
      </Slide>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">501 Calabresa l</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">58,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Calabresa Fatiada, Cebola e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">502 Calabresa ll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Calabresa Fatiada, Cebola, Mussarela e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">503 Calabresa lll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Calabresa Fatiada, Cebola, Catupiry, Mussarela e
          Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">504 Mussarela l</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">58,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Mussarela, Tomate e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">505 Mussarela ll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Mussarela, Catupiry, Tomate e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">506 Mussarela lll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Presunto, Tomate, Mussarela e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">507 Portuguesa l</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">58,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Presunto, Ovos, Azeitonas Pretas, Cebola, Mussarela e
          Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">508 Portuguesa ll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Presunto, Ovos, Azeitonas Pretas, Catupiry, Cebola,
          Mussarela e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">509 Portuguesa lll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Presunto, Ovos, Azeitonas Pretas, Cebola, Milho,
          Ervilha, Mussarela e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">510 Frango com Catupiry l</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Frango Desfiado, Catupiry, Mussarela e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">511 Frango com Catupiry ll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Frango Desfiado, Catupiry, Milho, Bacon, Mussarela e
          Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">512 Palmito l</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">58,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Palmito, Mussarela e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">513 Palmito ll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Palmito, Azeitonas Pretas, Catupiry, Mussarela e
          Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">514 Palmito lll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Palmito, Azeitonas Pretas, Catupiry, Milho, Ervilha,
          Mussarela e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">515 Brócolis com Catupiry</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Brócolis Refogado com Alho na Manteiga, Catupiry,
          Mussarela e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">516 Atum l</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">58,00</h4>
          </div>
        </div>

        <p className="item-desc">Molho de Tomate, Atum, Mussarela e Orégano.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">517 Atum ll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Atum, Azeitonas Pretas, Cebola, Mussarela e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">518 Atum lll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Atum, Azeitonas Pretas, Cebola, Catupiry, Mussarela e
          Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">519 Milho Verde com Catupiry</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">58,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Milho Verde, Catupiry, Mussarela e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">520 Marguerita l</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">58,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Azeitonas Pretas, Tomate, Mussarela e Manjericão.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">521 Marguerita ll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Azeitonas Pretas, Tomate, Catupiry, Mussarela e
          Manjericão.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">522 Lombo Canadense l</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">58,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Lombinho Defumado, Mussarela ola e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">523 Lombo Canadense ll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Lombinho Defumado, Catupiry, Mussarela e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">524 Lombo Canadense lll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Lombinho Defumado, Cheddar, Mussarela e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">525 4 Queijos l</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Provolone, Gorgonzola, Catupiry, Mussarela e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">526 4 Queijos ll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">64,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Provolone, Gorgonzola, Catupiry, Mussarela, Orégano e
          Rúcula.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">527 4 Queijos lll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">66,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Provolone, Gorgonzola, Catupiry, Mussarela, Tomate
          Seco, Orégano e Rúcula.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">528 5 Queijos</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">66,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Provolone, Gorgonzola, Catupiry, Cheddar, Mussarela e
          Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">529 Tomate Seco l</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">68,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Azeitonas Pretas, Tomate Seco, Mussarela, Orégano e
          Rúcula.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">530 Tomate Seco ll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">68,00</h4>
          </div>
        </div>

        <p className="item-desc">
          Molho de Tomate, Azeitonas Pretas, Tomate Seco, Catupiry, Mussarela,
          Orégano e Rúcula.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">531 Gordão l</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">76,00</h4>
          </div>
        </div>

        <p className="item-desc">
          (Você escolhe 2 sabores 1/2 a 1/2) 1/2 Calabresa, 1/2 Atum, 1/2 Lombo
          Canadense, 1/2 Frango + Molho de Tomate, Ovos, Azeitonas Pretas,
          Palmito, Bacon, Milho, Ervilha, Catupiry, Cebola, Mussarela e Orégano.
        </p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">532 Gordão ll</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">79,00</h4>
          </div>
        </div>

        <p className="item-desc">
          (Você escolhe 2 sabores 1/2 a 1/2) 1/2 Calabresa, 1/2 Atum, 1/2 Lombo
          Canadense, 1/2 Frango + Molho de Tomate, Ovos, Azeitonas Pretas,
          Palmito, Bacon, Milho, Ervilha, Catupiry, Cebola, Tomate Seco,
          Mussarela, Orégano e Rúcula.
        </p>
      </Item>

      <Slide left cascade duration={500}>
        <h1 className="menu-title">Pizzas Doces</h1>
      </Slide>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">533 Banana Especial</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">Banana, Nutella, Creme de Leite e Canela.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">534 Prestígio</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">Nutella, Coco Ralado e Leite Condensado.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">540 Brigadeiro</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">Chocolate e Chocolate Granulado.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">541 Chocolate com Banana</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">Chocolate, Banana Fatiada e Canela.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">542 Mesclada</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">Chocolate Branco e Chocolate Preto.</p>
      </Item>

      <Item>
        <div className="item-separator">
          <h4 className="item-title">543 Confetti</h4>

          <div className="item-price-wrapper">
            <h4 className="item-price">62,00</h4>
          </div>
        </div>

        <p className="item-desc">Chocolate e Confetti.</p>
      </Item>
    </Menu>
  </>
);

export default Pizzas;
